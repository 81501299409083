.anime-header {
    width: 100%;
    /* background-color: black !important; */
    background: rgba(0, 0, 0, 0.63) !important;
}

.anime-search-icon {
    float: right;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.anime-key-icon {
    padding-top: 0.5%;
    height: 100%;
    float: left;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.anime-key-icon:hover {
    color: #000000;
}

.anime-header-button {
    position: relative;
    border-radius: 50px;
    color: white;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 5% 15% 5% 15%;
    border-color: transparent;
    font-size: 16px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.anime-header-button:hover {
    background-color: rgba(255, 255, 255, 0.6);
    transform: translateY(-4px);
}

.anime-header-more-button {
    position: relative;
    color: white;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 5% 8%;
    margin-left: 30%;
    border-color: transparent;
    float: right;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

@media only screen and (max-width: 768px) {
    .anime-header-more-button {
        background-color: rgba(0, 0, 0, 0);
    }
}

.anime-header-more-button:hover {
    background-color: rgba(255, 255, 255, 0.6);
    transform: translateY(-4px);
}