.single-anime{
  min-height: 100vh;
  overflow: hidden;
  color: white;
  background-size: cover;
  background-color: black;
}

.single-top-wrapper
.animia-single-nft-breadcrumb{
  margin: 2% 0 ;
}

.animia-light-text{
  color: rgb(197, 197, 197);
}

.animia-light-text:hover{
  color: #ffffff;
}

.anime-content{
  min-height: 76vh;
  background-color: black;
}

.content-text{
  margin-left: 1vw;
  margin-right: 1vw;
}

.content-info-title-text{
  font-size: 110%;
  font-weight: 500;
}

.content-info-text{
  font-size: 100%;
  color: rgba(0, 0, 0, 0.78);
}